<template>
  <div>
    <d-body-top-bar
      v-if="custom === 'default'"
      :title="menu"
    >
      <div slot="tree">
        <label
          class="inactive-tree pointer link-area"
          @click="$router.push({ name: 'sms_campaign'})"
        >
          {{ $t('views.marketing.content.middle.sms-campaign') }}
        </label>
        <label class="inactive-tree ml-1 mr-1">
          /
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ title }}
        </label>
      </div>
    </d-body-top-bar>
    <validation-observer
      ref="emailObserver"
      v-slot="{ invalid }"
      slim
    >
      <div
        class="container-fluid"
      >
        <b-row>
          <b-col
            :cols="innerWidth ? '3' : '12'"
          >
            <b-card
              :class="custom ==='custom' ? 'custom-background-color' : ''"
            >
              <sms-campaign-form
                :mode="mode"
                :custom="custom"
                :customer="customer"
                :validator="validator"
                :group-list="groupList"
                :activity-list="activityList"
                :isSmsCampaign="isSmsCampaign"
                :recipient-list="recipientList"
                :email-campaign="emailCampaign"
                :custom-sent-option="customSentOption"
                :subscription-plan-list="subscriptionPlanList"
                @on:custom-sent-option:update="onCustomSentOptionUpdate"
              />
              <div v-if="$route.name === 'marketing_sms_new' || $route.name === 'marketing_sms_update'" class="mt-3">
                <b-row>
                  <b-col cols="12" align="center">
                    <label class="settings-title">
                      {{ $t('components.form.marketing.campaign.sms-credits') }}
                    </label>
                  </b-col>
                  <b-col cols="12" align="center">
                    <label class="settings-title">
                      {{ clubCredit }}
                    </label>
                  </b-col>
                </b-row>
                <!--                <b-row>-->
                <!--                  <b-col align="center">-->
                <!--                    <label class="link-blue-light-selected">-->
                <!--                      {{ $t('components.form.marketing.campaign.buy-sms-credit') }}-->
                <!--                    </label>-->
                <!--                  </b-col>-->
                <!--                </b-row>-->
              </div>
            </b-card>
          </b-col>
          <b-col
            :cols="innerWidth ? '9' : '12'"
          >
            <b-card
              :class="custom ==='custom' ? 'custom-background-color' : ''"
            >
              <b-row class="mb-2">
                <b-col align="left">
                  <label class="settings-title">
                    {{ $t('components.form.marketing.campaign.email-content') }}
                  </label>
                </b-col>
                <b-col
                  v-if="custom !== 'custom'"
                  align="right"
                >
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.draft"
                    class="d-btn-sm font-text-title btn d-btn-default mr-2"
                    @on:button-click="onDraft(invalid)"
                  />
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.next-step"
                    class="d-btn-sm font-text-title btn d-btn-danger"
                    @on:button-click="onNextStep(invalid)"
                  />
                </b-col>
                <b-col v-else align="right">
                  <d-button
                    :class="invalid ? 'disabled' : ''"
                    text="general.actions.send"
                    class="d-btn-sm font-text-title btn d-btn-danger"
                    @on:button-click="onNextStep(invalid)"
                  />
                </b-col>
              </b-row>
              <b-textarea
                v-model="emailCampaign.content"
                :rows="innerWidth ? '20' : '10'"
                :state="count === 0 ? true : 'false'"
                :placeholder="$t('components.form.marketing.campaign.sms-content-placeholder')"
                no-resize
                size="sm"
                maxlength='145'
                class="background-light-blue-inputs mt-2"
              >
              </b-textarea>
              <div class="mt-3">
                {{ $t('components.form.marketing.campaign.last-characters') }}
                <label class="font-weight-bold">
                  {{ count }}
                </label>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import SmsCampaign from "@/classes/doinsport/SmsCampaign";
import {
  getSmsCampaign,
  postSmsCampaign,
  putSmsCampaign,
  putSmsCampaignStatus
} from "@api/doinsport/services/campaign/sms-campaign.api";
import {getCurrentClub} from "@api/doinsport/services/club/club.api";

export default {
  props: {
    isSmsCampaign: {
      type: Boolean,
      default: false
    },
    custom: {
      type: String,
      default: 'default'
    },
    customer: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    mode: 'create',
    emailCampaign: new SmsCampaign(),
    customSentOption: false,
    recipientList: [],
    activityList: [],
    smsAccountBalance: 0,
    subscriptionPlanList: [],
    groupList: [],
    validator: false,
  }),
  components: {
    SmsCampaignForm: () => import('@form/marketing/CampaignForm')
  },
  computed: {
    title() {
      return this.emailCampaign.id ? this.$t('views.marketing.content.middle.edit-campaign') : this.$t('views.marketing.content.middle.new-campaign');
    },
    menu() {
      return this.emailCampaign.id ? this.$t('views.marketing.content.middle.campaign-edition') : this.$t('views.marketing.content.middle.campaign-creation');
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    clubCredit() {
      return this.smsAccountBalance;
    },
    count() {
      let smsLength = 0;

      for (const char of this.emailCampaign.content) {
        switch (char) {
          case "\n":
          case "\t":
          case "[":
          case "]":
          case "\\":
          case "^":
          case "{":
          case "}":
          case "|":
          case "€":
            smsLength += 2;
            break;
          default:
            smsLength += 1;
        }
      }
      return 145 - smsLength;
    }
  },
  methods: {
    loadSmsCredit() {

      getCurrentClub().then((response) => {
        this.smsAccountBalance = response.data.smsAccountBalance;
      });

      return this.smsAccountBalance;
    },
    onCustomSentOptionUpdate(scope) {
      this.customSentOption = scope;
    },
    onEditorContentUpdate(content) {
      this.emailCampaign.content = content;
    },
    onNextStep(invalid) {
      if (!invalid) {
        if (this.$route.name === 'marketing_sms_new' || this.custom === 'custom') {
          if (this.customSentOption && this.emailCampaign.clients.length === 0) {
            this.$flash(null, this.$t('components.form.marketing.campaign.no-selected-recipents'));
          } else {
            this.checkFilters();
            if (this.custom === 'custom') {
              this.emailCampaign.status = 'pending';
            }
            postSmsCampaign(this.emailCampaign)
              .then(
                (response) => {
                  if (this.custom === 'custom') {
                    this.$emit('on:sms:sent');
                  } else {
                    this.goToReportPage(response);
                  }
                }
              )
            ;
          }
        } else {
          this.checkFilters();
          delete this.emailCampaign.status;
          putSmsCampaignStatus({id: this.$route.params.id, status: 'draft'})
            .then(
              (response) => {
                putSmsCampaign(this.emailCampaign)
                  .then(
                    (response) => {
                      this.goToReportPage(response);
                    }
                  )
                ;
              }
            )
          ;
        }
      }
    },
    validateForm() {
      this.validator = !this.validator;
    },
    onDraft(invalid) {
      if (!invalid) {
        if (this.customSentOption && this.emailCampaign.clients.length === 0) {
          this.$flash(null, this.$t('components.form.marketing.campaign.no-selected-recipents'));
        } else {
          this.checkFilters();
          if (null === this.emailCampaign.id) {
            postSmsCampaign(this.emailCampaign).then(
              (response) => {
                this.$router.push({name: 'sms_campaign'});
              })
            ;
          } else {
            delete this.emailCampaign.status;
            putSmsCampaignStatus({id: this.$route.params.id, status: 'draft'})
              .then(
                (response) => {
                  putSmsCampaign(this.emailCampaign)
                    .then((response) => {
                      this.$router.push({name: 'sms_campaign'});
                    })
                })
            ;
          }
        }
      }
    },
    checkFilters() {
      if (this.customSentOption) {
        this.emailCampaign.filters = null;
      } else {
        delete this.emailCampaign.clients;
      }
    },
    loadEmailCampaign() {
      getSmsCampaign(this.$route.params.id)
        .then((response) => {
          this.emailCampaign = response.data;
          this.customSentOption = this.emailCampaign.filters === null;
        })
      ;
    },
    goToReportPage(response) {
      const reportRoute = {
        name: 'campaign_sms_report',
        params: {
          id: response.data.id,
        }
      }

      this.$router.push(reportRoute);
    }
  },
  created() {
    this.loadSmsCredit();
    if (this.custom === 'custom') {
      this.customSentOption = true;
    }
  },
  mounted() {
    if (this.$route.name === 'marketing_sms_update') {
      this.loadEmailCampaign();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

/deep/ .card .card-body {
  min-height: 100vh;
}

@media only screen and (max-width: 991px) {
  /deep/ .card .card-body {
    min-height: min-content;
  }
}

.custom-background-color {
  background-color: transparent;
}
</style>
