import {_delete, _get, _post, _put} from "@api/doinsport/services/httpService";
import { URL_CLIENT } from "../index";

export const URI = URL_CLIENT + '/locks';

export const getClientLock = (clientId) => _get(URI + '/' + clientId);

export const postClientLock = (data) => _post(URI, data);

export const putClientLock = (id, data) => _put(URI + '/' + id, data);

export const deleteClientLock = (id) => _delete(URI + '/' + id);
