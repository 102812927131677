<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    size="xl"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
    class="m-0"
  >
    <b-row>
      <b-col align="left" class="modal-title-class">
        {{ $t('views.client.customer-edition') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3"/>
    <customer-form
      :class="$store.getters['layout/getInnerWidth'] > 900 ? '' : 'p-0'"
      mode="update"
      @on:customer:update="$bvModal.hide(modalId); $emit('on:customer:update')"
    />
  </b-modal>
</template>
<script>

export default {
  props: {
    modalId: {
      type: String,
      default: 'default'
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomerForm: () => import('@views/client/new/Index'),
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 10px;
  padding-bottom: 15px;
  border-radius: 8px;
}

@media (min-width: 700px) and (max-width: 800px) {
  /deep/ .modal-dialog {
    min-width: 90%;
  }
}

@media (min-width: 1200px) {
  /deep/ .modal-xl {
    min-width: 90%;
  }
}

.modal-title-class {
  text-align: center;
  font: normal normal 500 30px Avenir;
  letter-spacing: 0;
  color: #3C3D43;
  opacity: 1;
}

</style>
