<template>
  <div>
    <div class="text-center">
      <b-row no-gutters>
        <b-col
          cols="12"
          align="right"
        >
          <d-dropdown-items
            identifier="profile"
            :menus="menus"
            @on:edit="display = !display"
            @on:lock="onLockClick"
          />
        </b-col>
        <b-col>
          <div class="product-box">
            <div class="product-img">
              <b-avatar
                :text="text"
                :src="avatar"
                :badge-variant="client.isLocked ? 'danger' : 'success'"
                :class="client.isLocked ? 'border-danger' : 'border-authorized'"
                variant="profile"
                size="200"
              >
                <template #badge>
                  <b-icon
                    :icon="client.isLocked ? 'dash' : 'check2'"
                  >
                  </b-icon>
                </template>
              </b-avatar>
              <div class="product-hover">
                <ul>
                  <li
                    class="pointer"
                    @click="uploadPhoto"
                    v-if="!client.identityPhoto"
                  >
                    <button class="btn" type="button">
                      <i class="icon-upload"></i>
                    </button>
                  </li>
                  <li
                    class="pointer"
                    @click="deleteIdentityPhoto"
                    v-else
                  >
                    <button
                      class="btn"
                      type="button"
                    >
                      <i class="icon-trash"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <label class="firstname-customer"> {{ client.firstName }} </label> <label
          class="lastname-customer">{{ client.lastName }}</label>
        </b-col>
      </b-row>

      <b-row v-if="subscriptionCards && subscriptionCards.length > 0" no-gutters>
        <b-col>
          <label class="detail-customer">
            {{ $t('views.client.index.infos.subscriber') }}
          </label>
        </b-col>
      </b-row>
      <!--
      <button
              class="mt-2 customer-btn-profile-info d-btn-infos btn d-btn-push">
        <i class="customer-btn-profile-icon pull-left fa fa-bell-o"> </i>
        <label class="btn-text-display text-center pointer">
          {{ $t('views.client.index.infos.btn-push') }}
        </label>
      </button>
      -->
      <button
        v-if="client.phoneNumber !== null"
        :class="$store.getters['layout/getInnerWidth'] < 1000 ? 'mr-2 pt-0 pb-2' : ''"
        class="mt-2 customer-btn-profile-info d-btn-infos btn d-btn-sms "
        @click="loadSmsCampaignModal"
      >
        <i
          class="customer-btn-profile-icon  fa fa-comment-o"
          :class="$store.getters['layout/getInnerWidth'] < 1000 ? 'pt-1' : 'pull-left'"
        />
        <label class="btn-text-display text-center pointer">
          {{ $t('views.client.index.infos.btn-sms') }}
        </label>
      </button>
      <button
        v-if="client.email !== null"
        :class="$store.getters['layout/getInnerWidth'] < 1000 ? 'mr-2 pt-0 pb-2' : ''"
        class="mt-2 customer-btn-profile-info d-btn-infos btn d-btn-email"
        @click="loadEmailCampaignModal"
      >
        <i
          class="customer-btn-profile-icon fa fa-envelope-o"
          :class="$store.getters['layout/getInnerWidth'] < 1000 ? 'pt-1' : 'pull-left'"
        > </i>
        <label class="btn-text-display text-center pointer">
          {{ $t('views.client.index.infos.btn-email') }}
        </label>
      </button>
    </div>
    <update-customer-modal
      :display="display"
      :customer="client"
      modal-id="customer-update-modal-id"
      @on:customer:update="reloadCustomer"
    />
    <lock-customer-modal
      v-if="lock"
      :display="displayLockModal"
      :lock="lock"
      modal-id="lock-modal-id"
      @on:lock:update="reloadCustomer"
      @on:lock:deleted="reloadCustomer"
    />
    <campaign-modal
      :modal-key="'modal-key' + modalKey"
      :isSmsCampaign="isSmsCampaign"
      :display="displayCampaign"
      :customer="client"
      mode="custom"
      modal-id="campaign-modal-id"
      @on:done="onCampaignSent"
    />
  </div>
</template>
<script>
import Lock from "@/classes/doinsport/Lock";
import {_get} from "@api/doinsport/services/httpService";
import {URL_CLIENT} from "@api/doinsport/services/client";
import CampaignModal from "@custom/clients/campaigns/CampaignModal";
import LockCustomerModal from "@custom/clients/lock/LockCustomerModal";
import {fromIdToIriReference, fromIriReferenceToId} from "@/utils/form";
import UpdateCustomerModal from "@custom/clients/infos/UpdateCustomerModal";
import {deleteClubClientIdentityPhoto} from "@api/doinsport/services/client/identity-photo/identity-photo.api";
import {capitalize} from "@/utils/string";

export default {
  data: () => ({
    lock: null,
    modalKey: 1,
    menus: [
      {
        name: 'edit',
        icon: 'icofont icofont-ui-edit'
      },
      {
        name: 'lock',
        icon: 'icofont icofont-ban'
      }
    ],
    display: false,
    btnLock: false,
    isSmsCampaign: false,
    displayCampaign: false,
    displayLockModal: false,
  }),
  components: {
    LockCustomerModal,
    CampaignModal,
    UpdateCustomerModal
  },
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    subscriptionCards: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    avatar() {
      if (this.client.identityPhoto) {
        return this.$filePath(this.client.identityPhoto.contentUrl);
      } else if (this.client.user) {
        if (this.client.user.avatar) {
          return this.$filePath(this.client.user.avatar.contentUrl);
        }
      }

      return null;
    },
    fullName() {
      return this.client.fullName;
    },
    text() {
      let name = '';
      let arrayName = this.fullName.split(' ');

      for (const el of arrayName) {
        name += capitalize(el[0]);
      }

      return name;
    }
  },
  methods: {
    onLockClick() {
      if (!this.btnLock) {
        this.btnLock = true;

        if (this.client.lock) {
          _get(this.client.lock['@id'])
            .then((response) => {
              this.lock = new Lock(response.data, {serialize: true});
            })
            .finally(() => {
              this.showLockModal();
              this.btnLock = false;
            })
          ;
        } else {
          this.lock = new Lock();
          this.lock.client = URL_CLIENT + '/' + this.client.id;
          this.showLockModal();
          this.btnLock = false;
        }
      }
    },
    showLockModal() {
      this.$nextTick(() => {
        this.displayLockModal = !this.displayLockModal;
      });
    },
    onCampaignSent() {
      this.modalKey++;
    },
    loadEmailCampaignModal() {
      this.isSmsCampaign = false;
      this.displayCampaign = !this.displayCampaign;
    },
    loadSmsCampaignModal() {
      this.isSmsCampaign = true;
      this.displayCampaign = !this.displayCampaign;
    },
    uploadPhoto() {
      const iriClient = fromIdToIriReference('/clubs/clients', this.client.id);
      this.$upload(this.uploaded, {entry: 'client', url: iriClient, target: 'postClientIdentityPhoto'});
    },
    uploaded(token) {
      this.reloadCustomer();
    },
    deleteIdentityPhoto() {
      if (this.client.identityPhoto) {
        deleteClubClientIdentityPhoto(fromIriReferenceToId('/clubs/clients/identity-photos/', this.client.identityPhoto['@id'])).then((response) => {
          this.reloadCustomer();
        })
      }
    },
    reloadCustomer() {
      this.$emit('on:reload-client');
    },
    initBadgeFontSize() {
      $(".b-avatar-badge").css('font-size', '3.3em');
    }
  },
  mounted() {
    this.initBadgeFontSize();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/client/details/_client-profile.scss";
@import "@lazy/_b-avatar";
</style>
