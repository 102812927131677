<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-footer
    hide-header-close
    class="m-0"
    size="lg"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <b-row>
      <b-col
        align="left"
        class="modal-title-class text-left pl-4 pr-4"
        cols="12"
      >
        {{ $t('views.client.customer-lock') }}
      </b-col>
      <b-col
        cols="12"
        class="pl-4 pr-4 mt-1"
      >
        <p class="lock-description">
          {{ $t('views.client.lock-description') }}
        </p>
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mb-2"/>
    <b-row>
      <b-col
        cols="12"
        class="pl-4 pr-4"
      >
        <lock-form
          :lock="lock"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col align="right">
        <d-button
          v-if="lock.id"
          :class="btnDeleteLoading ? 'disabled mr-2' : 'mr-2'"
          :icon="btnDeleteLoading ? 'fa fa-spinner fa-spin' : ''"
          text="general.actions.delete"
          class="d-btn-sm d-btn btn d-btn-danger font-text-title ml-1"
          @on:button-click="onDelete"
        />

        <d-button
          :class="btnSaveLoading ? 'disabled' : ''"
          :icon="btnSaveLoading ? 'fa fa-spinner fa-spin' : ''"
          text="general.actions.validate"
          class="d-btn-sm d-btn btn d-btn-primary-new font-text-title"
          @on:button-click="onSave"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import Lock from "@/classes/doinsport/Lock";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {deleteClientLock, postClientLock, putClientLock} from "@api/doinsport/services/client/lock/lock.api";

export default {
  data: () => ({
    btnSaveLoading: false,
    btnDeleteLoading: false,
  }),
  props: {
    lock: {
      type: Object,
      default: this
    },
    modalId: {
      type: String,
      default: 'default'
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LockForm: () => import('@form/client/LockForm'),
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    onDelete() {
      if (!this.btnDeleteLoading) {
        this.btnDeleteLoading = true;

        deleteClientLock(this.lock.id)
          .then(() => {
            this.$emit('on:lock:deleted');
            this.hide();
          })
          .finally(() => this.btnDeleteLoading = false)
        ;
      }
    },
    onSave() {
      let serializedLock = null
      try {
        serializedLock = new Lock(this.lock, {serialize_server: true});
      } catch (e) {
        if (isNotUndefinedAndNotNull(e.params)) {
          for (const param of e.params) {
            for (const key of Object.keys(param)) {
              if (!param[key]) {
                this.$flash(null, this.$t(`views.client.lock-errors.${key}`));
              }
            }
          }
        }
      }

      if (null !== serializedLock && !this.btnSaveLoading) {
        this.btnSaveLoading = true;

        this.getLockRequest(serializedLock)
          .then((response) => {
            this.$emit('on:lock:update', response.data);
            this.hide();
          })
          .finally(() => this.btnSaveLoading = false)
        ;
      }
    },
    getLockRequest(lock) {
      return lock.id ? putClientLock(lock.id, lock) : postClientLock(lock);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";

.lock-description {
  text-align: left;
  font: normal normal normal 14px/21px Avenir;
  letter-spacing: 0.2px;
  color: #565555;
  opacity: 1;
}
</style>
